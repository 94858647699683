import React, { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

import { FaDownload } from "react-icons/fa";
import api from "../../services/api";
import { toast } from "react-toastify";
import { LinkContainer } from "react-router-bootstrap";

export function DiocesesReports() {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleDownload = useCallback(async (url) => {
    setDownloadLoading(true);
    await api
      .get(`/api${url}`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloadLoading(false);

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );

        setDownloadLoading(false);

        console.log(errors);
      });
  }, []);

  return (
    <Container>
      <LinkContainer to="/dioceses">
        <Button variant="link">Voltar</Button>
      </LinkContainer>
      <div className="text-center">
        <h1>Relatórios das Dioceses</h1>
      </div>
      <Row className="mt-5">
        <Table striped bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descrição</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "middle" }}>1</td>
              <td style={{ verticalAlign: "middle", fontSize: "1.2rem" }}>
                <strong>
                  <u>Relatório Geral com Regionais</u>
                </strong>
              </td>
              <td
                className="text-wrap"
                style={{ width: "25rem", verticalAlign: "middle" }}
              >
                <i>Dados gerais da Diocese, juntamente com a Regional</i>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <ButtonGroup aria-label="Action General">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-excel-general-report`}>
                        Clique para baixar
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleDownload("/diocese/reports/general")}
                    >
                      {downloadLoading ? (
                        "Aguarde ..."
                      ) : (
                        <>
                          <FaDownload size={16} className="mr-2" />
                          Baixar
                        </>
                      )}
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>

            <tr>
              <td style={{ verticalAlign: "middle" }}>1</td>
              <td style={{ verticalAlign: "middle", fontSize: "1.2rem" }}>
                <strong>
                  <u>Relatório Contatos</u>
                </strong>
              </td>
              <td
                className="text-wrap"
                style={{ width: "25rem", verticalAlign: "middle" }}
              >
                <i>Dados gerais de contato da Diocese</i>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <ButtonGroup aria-label="Action General">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-excel-general-report`}>
                        Clique para baixar
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleDownload("/diocese/reports/contact")}
                    >
                      {downloadLoading ? (
                        "Aguarde ..."
                      ) : (
                        <>
                          <FaDownload size={16} className="mr-2" />
                          Baixar
                        </>
                      )}
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
