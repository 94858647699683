import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  CardDeck,
  Col,
  Container,
  Jumbotron,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaCheckCircle,
  FaDownload,
  FaPlus,
  FaTimes,
  FaEnvelope,
} from "react-icons/fa";
import { FiCalendar, FiInfo } from "react-icons/fi";
import { BiMailSend } from "react-icons/bi";
import { LinkContainer } from "react-router-bootstrap";

import api from "../../services/api";

import { formatDate } from "../../utils/formatDate";
import MissionaryCollectContribTable from "../../components/MissionaryCollectContrib";

function MissionaryCollectionView() {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [missionary, setMissionary] = useState({});

  useEffect(() => {
    async function loadMissionary() {
      setLoading(true);

      await api
        .get(`/api/missionary-collection/${id}`)
        .then((resp) => {
          setLoading(false);
          setMissionary(resp.data);

          if (resp.data.finished) {
            return toast.info("‼ Coleta Missionária já FINALIZADA!");
          }
        })
        .catch((error) => {
          setLoading(false);
          return toast.error(
            "❗ Não foi possível carregar a Coleta Missionária"
          );
        });
    }
    loadMissionary();
  }, [id]);

  const handleChangeStatus = useCallback(async () => {
    try {
      const { data } = await api.put(`/api/missionary-collection/${id}`, {
        finished: !missionary.finished,
      });

      setMissionary(data);

      toast.success("Status alterado com sucesso!");
    } catch (error) {
      toast.error("Não foi possível alterar o status!");
    }
  }, [id, missionary.finished]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Coleta Missionária Finalizada</Popover.Title>
      <Popover.Content>
        Esta coleta missionária já foi finalizada, e não há possibiliade de
        fazer mais nenhuma ação que altere <strong>valores</strong> ou{" "}
        <strong>estados</strong>
      </Popover.Content>
    </Popover>
  );

  const handleDownloadDiocesesNotContrib = useCallback(async () => {
    await api
      .get(
        `/api/collection-missionary-contribuitions/missionary-collections/diocese-not-contrib/${id}`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, [id]);

  const handleSendMailDiocesesNotContrib = useCallback(async () => {
    await api
      .get(
        `/api/collection-missionary-contribuitions/missionary-collections/diocese-not-contrib-mail/${id}`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        toast.success("✅ Serviço de e-mails criado!");
      })
      .catch((errors) => {
        toast.error("❌ Não foi possível enviar, tente novamente mais tarde!");
      });
  }, [id]);

  const handleSendMailFirstMessage = useCallback(async () => {
    await api
      .get(
        `/api/collection-missionary-contribuitions/missionary-collections/first-message`
      )
      .then((resp) => {
        toast.success("✅ Serviço de e-mails criado!");
      })
      .catch((errors) => {
        toast.error("❌ Não foi possível enviar, tente novamente mais tarde!");
      });
  }, []);

  const handleDownloadDiocesesGeneralExport = useCallback(async () => {
    await api
      .get(
        `/api/collection-missionary-contribuitions/report/general-by-id/${id}`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, [id]);

  return (
    <Container fluid>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row>
            <LinkContainer to="/missionary-collection">
              <Button variant="link">Voltar</Button>
            </LinkContainer>
          </Row>

          <Row>
            <Col md="7">
              <Jumbotron fluid className="h-100">
                <Container>
                  <h1>{missionary.description}</h1>
                  <p>
                    <strong>Código:</strong> {missionary.cod}
                  </p>

                  <p>
                    <strong>Status: </strong>
                    {missionary.finished ? (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={popover}
                      >
                        <span className="p-1 bg-success text-light">
                          Finalizada <FiInfo className="ml-1" size={20} />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <span className="p-1 bg-danger text-light">
                        Não Finalizada
                      </span>
                    )}
                  </p>
                </Container>
              </Jumbotron>
            </Col>

            <Col md="5">
              <div className="d-flex flex-column bd-highlight justify-content-between">
                <div className="bd-highlight">
                  <Button
                    variant="secondary"
                    size="lg"
                    block
                    onClick={() => {
                      history.push(
                        `/missionary-collection-contrib/create/${id}`
                      );
                    }}
                    disabled={missionary.finished}
                  >
                    <FaPlus className="mr-1" /> Nova Contribuição
                  </Button>
                </div>
                <div className="pt-4 bd-highlight">
                  <Button
                    variant="secondary"
                    size="lg"
                    block
                    onClick={handleSendMailFirstMessage}
                    disabled={missionary.finished}
                  >
                    <BiMailSend className="mr-2" /> Enviar Carta de Aviso
                    Inicial
                  </Button>
                </div>

                <div className="pt-4 bd-highlight">
                  <Button
                    variant={missionary.finished ? "danger" : "success"}
                    size="lg"
                    block
                    onClick={handleChangeStatus}
                  >
                    {missionary.finished ? (
                      <>
                        <FaTimes className="mr-2" />
                        Retirar Finalizado
                      </>
                    ) : (
                      <>
                        <FaCheckCircle className="mr-2" /> Finalizar
                      </>
                    )}
                  </Button>
                </div>

                <div className="pt-4 bd-highlight">
                  <Button
                    variant="primary"
                    size="lg"
                    block
                    onClick={handleDownloadDiocesesNotContrib}
                  >
                    <FaDownload className="mr-2" />
                    Dioceses que não contribuíram
                  </Button>
                </div>

                <div className="pt-4 bd-highlight">
                  <Button
                    variant="primary"
                    size="lg"
                    block
                    onClick={handleSendMailDiocesesNotContrib}
                  >
                    <FaEnvelope className="mr-2" />
                    E-mail de aviso de prazo de pagamento
                  </Button>
                </div>

                <div className="pt-4 bd-highlight">
                  <Button
                    variant="primary"
                    size="lg"
                    block
                    onClick={handleDownloadDiocesesGeneralExport}
                  >
                    <FaDownload className="mr-2" />
                    Relatório Geral
                  </Button>
                </div>

                <div className="pt-4 bd-highlight">
                  <CardDeck>
                    <Card bg="light">
                      <Card.Body>
                        <Card.Title>Data de Início</Card.Title>
                        <Card.Text>
                          <FiCalendar className="mr-2" />
                          {formatDate(missionary.begin_dt)}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {missionary.end_dt && (
                      <Card bg="light">
                        <Card.Body>
                          <Card.Title>Data de Finalização</Card.Title>
                          <Card.Text>
                            <FiCalendar className="mr-2" />
                            {formatDate(missionary.end_dt)}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    )}
                  </CardDeck>
                </div>
              </div>
            </Col>
          </Row>

          <Card className="mt-3 mb-3">
            <Card.Body>
              <MissionaryCollectContribTable
                url={`/api/collection-missionary-contribuitions/missionary-collections/${id}`}
                id={id}
                finished={missionary.finished}
              />
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  );
}

export default MissionaryCollectionView;
