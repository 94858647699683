/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import {
  FaRegChartBar,
  FaHome,
  FaRegBuilding,
  FaRegUserCircle,
  FaUsers,
  FaBox,
  FaMoneyBill,
} from "react-icons/fa";
import { BiChurch, BiDonateHeart } from "react-icons/bi";
import { GiCoins, GiPopeCrown } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";

import Logo_Horizontal from "../../../assets/Logo_horizontal_laranja.png";
import { useReactAuth } from "../../../contexts/hooks/AuthContext";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const sidebarClasses = {
  root: "ps-sidebar-root",
  container: "ps-sidebar-container",
  image: "ps-sidebar-image",
  backdrop: "ps-sidebar-backdrop",
  collapsed: "ps-collapsed",
  toggled: "ps-toggled",
  rtl: "ps-rtl",
  broken: "ps-broken",
};

export const menuClasses = {
  root: "ps-menu-root",
  menuItemRoot: "ps-menuitem-root",
  subMenuRoot: "ps-submenu-root",
  button: "ps-menu-button",
  prefix: "ps-menu-prefix",
  suffix: "ps-menu-suffix",
  label: "ps-menu-label",
  icon: "ps-menu-icon",
  subMenuContent: "ps-submenu-content",
  SubMenuExpandIcon: "ps-submenu-expand-icon",
  disabled: "ps-disabled",
  active: "ps-active",
  open: "ps-open",
};

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const { user, signOut } = useReactAuth();
  const [theme, setTheme] = useState("dark");
  const [hasImage, setHasImage] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    user.profile === "admin" ? setIsAdmin(true) : setIsAdmin(false);
  }, [user.profile]);

  function handleSignOut() {
    signOut();
  }

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
            themes[theme].menu.menuContent,
            hasImage && !collapsed ? 0.4 : 1
          )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <ProSidebar
      image={image}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      backgroundColor={hexToRgba(
        themes[theme].sidebar.backgroundColor,
        hasImage ? 0.9 : 1
      )}
      rootStyles={{
        color: themes[theme].sidebar.color,
      }}
    >
      <div
        style={{
          padding: "24px",
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: 14,
          letterSpacing: "1px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <img src={Logo_Horizontal} alt="Logo" width="140" />
      </div>

      <Menu iconShape="round" menuItemStyles={menuItemStyles}>
        <MenuItem icon={<FaHome />} component={<Link to="/home" />}>
          Home
        </MenuItem>
        <MenuItem icon={<FaRegChartBar />} component={<Link to="/dashboard" />}>
          Dashboard
        </MenuItem>

        <SubMenu
          icon={<FaUsers />}
          label="Usuários"
          className={!isAdmin && "hidden"}
        >
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/users" />}
          >
            Listar
          </MenuItem>
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/users-create" />}
          >
            Criar Novo
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<FaRegBuilding />} label="Regionais CNBB">
          <MenuItem component={<Link to="/parish-region" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/parish-region-create" />}>
            Criar Nova
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<GiPopeCrown />} label="Dioceses">
          <MenuItem component={<Link to="/dioceses" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/diocese-create" />}>
            Criar Nova
          </MenuItem>
          <MenuItem component={<Link to="/diocese-reports" />}>
            Relatórios
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<BiChurch />} label="Paróquias">
          <MenuItem component={<Link to="/parish" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/parish-create" />}>
            Criar Nova
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<HiOutlineUserGroup />} label="Grupos IAM">
          <MenuItem component={<Link to="/safe-box" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/safe-box-create" />}>
            Criar Novo
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<BiDonateHeart />} label="Doações Cofrinho">
          <MenuItem component={<Link to="/donation" />}>Listar</MenuItem>
          <MenuItem component={<Link to="/donation-create" />}>
            Criar Nova
          </MenuItem>
          <MenuItem component={<Link to="/safe-box/reports" />}>
            Relatórios
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<GiCoins />} label="Coleta Missionária">
          <MenuItem component={<Link to="/missionary-collection" />}>
            Listar
          </MenuItem>
          <MenuItem component={<Link to="/missionary-collection-create" />}>
            Nova Coleta
          </MenuItem>
        </SubMenu>

        <SubMenu icon={<FaBox />} label="Campanha Missionária">
          <MenuItem component={<Link to="/missionary-campaign" />}>
            Listar
          </MenuItem>
          <MenuItem component={<Link to="/missionary-campaign-create" />}>
            Nova Campanha
          </MenuItem>

          <MenuItem component={<Link to="/missionary-campaign-setting" />}>
            Configurações
          </MenuItem>

          <MenuItem component={<Link to="/missionary-campaign-report" />}>
            Relatórios
          </MenuItem>
        </SubMenu>

        <SubMenu
          icon={<FaMoneyBill />}
          label="Financeiro"
          className={!isAdmin && "hidden"}
        >
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/finance" />}
          >
            Início
          </MenuItem>
        </SubMenu>

        <SubMenu
          icon={<FiSettings />}
          label="Configurações"
          className={!isAdmin && "hidden"}
        >
          <MenuItem
            className={!isAdmin && "hidden"}
            component={<Link to="/global-settings" />}
          >
            Listar
          </MenuItem>
        </SubMenu>
      </Menu>

      <Menu iconShape="round" menuItemStyles={menuItemStyles}>
        <SubMenu label={`Olá, ${user.name}`} icon={<FaRegUserCircle />}>
          <MenuItem>Perfil</MenuItem>
          <MenuItem>Segurança</MenuItem>
        </SubMenu>
      </Menu>

      <div
        className="sidebar-btn-wrapper"
        style={{
          padding: "20px 24px",
        }}
      >
        <div className="d-flex flex-column bd-highlight">
          <div className="bd-highlight mt-3">
            <button className="sidebar-btn" onClick={handleSignOut}>
              <FiLogOut />
              <span>Sair</span>
            </button>
          </div>
        </div>
      </div>
    </ProSidebar>
  );
};

export default Aside;
